<template>
    <section class="mx-auto my-16 md:my-24 px-4 lg:px-0 w-full lg:w-1024">
        <div class="inner">
            <p class="eula">
                <br><b class="font--xlarge" style="color:#49C5FE">neoAssess FAQ</b>
                <br><br><b>1. What is neoAssess?</b> <br><br>
                neoAssess is an online, secure, English assessment test that accurately identifies test-takers’ English
                language abilities the first time they take the test, or at regular intervals (longitudinal assessment).
                <br><br>
                The test is adaptive which means it stops when the test-takers’ maximum level is identified. neoAssess
                records results instantly in the Cloud and are downloadable in Excel format or APIs for database extraction.

                <br><br><b>2. How can I use neoAssess for recruiting? </b><br><br>
                neoAssess offers a quick, secure, accurate and low-cost way to manage thousands of applicants during the
                costly recruitment process. It eliminates the need to interview all candidates, providing recruitment teams
                with a pre-qualified pool of candidates to choose from.

                <br><br><b>3. Can neoAssess help me audit my staff’s skills?</b><br><br>
                Our bulk-upload option makes it very easy to not only test all your staff, but also group them by job
                function, department, or faculty.

                <br><br><b>4. Can neoAssess help our university track graduation requirements?</b><br><br>
                Yes. Nearly all of our university clients test incoming first year and current students to create a baseline
                for their English graduation requirements. Semester re-assessment (longitudinal assessment) helps
                universities predict whether they will meet their faculty’s grad requirement. neoAssess evaluates learner’s
                English skills with precision both in their initial assessment and at regular intervals and will identify
                areas for improvement to progress to their target level in time to graduate. This helps ensure they graduate
                ready for job postings in the global economy. The sooner student baseline tracking starts the more the
                university’s reputation improves among employers anywhere.

                <br><br><b>5. Are neoAssess results accurate?</b> <br><br>
                neo results are fully aligned to standards set by the Common European Framework of Reference for Languages
                (CEFR), and all other international tests like TOEFLTM, IELTS, and TOEIC likewise align and correlate to
                this global standard. <br><br>
                neoAssess verifies candidates' English language proficiency by comparing their answers to the required CEFR
                “Can Do” statements for each of the CEFR levels. This adaptive test ends when it establishes the limit of
                the individual’s English proficiency, so it is relatively short and very efficient.

                <br><br><b>6. We have used other programs in the past with poor results, what makes neoAssess a good option
                    for our company?</b><br><br>
                neoAssess is the only program in its class that has 30+ years of EdTech experience using machine learning
                and AI, collecting user data from of over 25 million English learners globally to validate the effectiveness
                of its test questions. <br><br>
                At neo nexgen we are confident at predicting learner outcomes and we can do so at a reasonable cost—we will
                never sell you something that you don’t need.

                <br><br><b>7. Will test takers receive their scores at the end of the test? </b><br><br>
                neo allows its clients to decide if they want test-takers to receive their scores at the end of the test, or
                whether these will be discussed in person or sent to the test-taker later. This can be easily managed from
                the neoDashboard and a URL that points them to your landing page for more information.

                <br><br><b>8. How will our recruiters or managers view test results?</b><br><br>
                Your recruiting teams or managers can download detailed assessment reports from the neoDashboard. These
                reports include a percentage-based skills assessment that details how well the candidate or staff member
                performed in the listening, vocabulary, social interaction, reading and grammar evaluations. The reports
                also provide you with suggested Areas for Improvement for each test-taker. This information allows your HR
                staff to define needed training gaps as learners move forward to their English learning goals.

                <br><br><b>9. Is there a Statement of Results (SoR)?</b><br><br>
                All test-takers can have the option of downloading a Sstatement of Results that details their scores and
                areas for improvement. There is a small additional fee for the SoR option.

                <br><br><b>10. How are we charged for Assessment?</b><br><br>
                You will have access to our easy-to-use Dashboard where all results are stored, you can verify each test and
                output to Excel. We simply count the number of tests taken and charge per test.

                <br><br><b>11. What if someone has hardware problems?</b> <br><br>
                The test can be taken 3-times if there are technical problems. But once the test is submitted it is
                considered complete.

                <br><br><b>12. What if I only want to allow one test attempt?</b><br><br>
                Some large corporate clients who receive thousands of applicants prefer this option for recruitment. In this
                case we are able to limit have a 1-time only test-taking option during the set-up.

                <br><br><b>13. Can I provide information for test-takers at the end of the test?</b><br><br>
                Yes, you can set up a webpage in any website to redirect upon submit test. This URL is yours to communicate
                guidance for all test-takers.

                <br><br><b>14. Can I proctor tests?</b><br><br>
                Yes, many Universities require this option. Test-takers can be given a test login as they enter the testing
                location where their ID has been checked. They log in and start the test once the proctor is ready.
            </p>
        </div>
    </section>
</template>
  
<script>
export default {};
</script>
  
<style lang="scss" scoped>.eula {
    text-align: justify;

    b {
        font-weight: bold;
    }
}</style>